// Import styles
import '../scss/main.scss';

import Inputmask from 'inputmask';
import Glide from '@glidejs/glide';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.header');

    const loading = header?.querySelector('.loading ');

    const burger = header?.querySelector('.burger');
    const navigation = header?.querySelector('.navigation');

    const sliders = [];

    if (loading && loading.closest('.home')) {
        document.body.style.overflow = 'hidden';

        window.addEventListener('load', () => {
            loading?.classList.add('loading--hide');
            setTimeout(() => {
                loading?.remove();

                document.body.style.overflow = '';

                sliders.forEach((slide) => {
                    slide.update();
                });
            }, 500);
        });
    } else loading?.remove();

    // Toggle navigation
    const toggleNavigation = () => {
        burger?.classList.toggle('burger--close');
        navigation?.classList.toggle('navigation--opened');
    };

    burger?.addEventListener('click', toggleNavigation);

    // Set submenu positions
    const setSubmenuPositions = () => {
        const submenus = navigation?.querySelectorAll('.submenu');
        submenus?.forEach((submenu) => {
            const { right } = submenu.getBoundingClientRect();
            const { right: navRight } = navigation.getBoundingClientRect();
            submenu.classList.toggle('submenu--left', right >= navRight);
        });
    };

    setSubmenuPositions();

    // Handle clicks on inactive navigation items
    const navNotActiveItems = navigation.querySelectorAll(
        '.navigation__item--not-active, .submenu__item--not-active'
    );
    navNotActiveItems.forEach((item) => {
        const link = item.querySelector('.navigation__link, .submenu__link');
        const submenu = item.querySelector('.submenu');
        if (submenu)
            link.onclick = (e) => {
                if (e.pointerType !== 'mouse' && window.innerWidth > 1144) {
                    [...navNotActiveItems]
                        .filter((notCurrentItem) => notCurrentItem !== item)
                        .forEach((notCurrentItem) => {
                            const submenu =
                                notCurrentItem.querySelector('.submenu');
                            submenu.classList.remove('submenu--active');
                        });

                    let submenuParent = item.closest('.submenu');
                    while (submenuParent) {
                        submenuParent.classList.add('submenu--active');
                        submenuParent = submenuParent
                            .closest('.submenu__item')
                            ?.closest('.submenu');
                    }

                    e.preventDefault();
                    submenu.classList.toggle('submenu--active');
                    accessibility
                        .querySelector('.accessibility__list')
                        .classList.remove('accessibility__list--opened');
                }
            };
    });

    navigation.querySelectorAll('.navigation__link').forEach((link) => {
        link.onmouseover = () => {
            navigation
                .querySelectorAll('.submenu.submenu--active')
                .forEach((submenu) => {
                    submenu.classList.remove('submenu--active');
                });
        };
    });

    // Set main height
    const setMainHeight = () => {
        const main = document.querySelector('.site-main');
        const headerHeight = header?.offsetHeight || 0;
        const footerHeight =
            document.querySelector('.footer')?.offsetHeight || 0;

        if (main) {
            main.style.minHeight = `calc(100vh - ${
                headerHeight + footerHeight
            }px)`;
        }
    };
    setMainHeight();

    // Accessibility
    const accessibility = document.querySelector('.accessibility');
    if (accessibility) {
        const MAX_FONT_SIZE = 18;
        const MIN_FONT_SIZE = 16;

        if (localStorage.getItem('grayscale') == 'true')
            document.body.classList.add('body--grayscale');

        let fontSize =
            parseFloat(localStorage.getItem('documentFontSize')) ||
            parseFloat(getComputedStyle(document.documentElement).fontSize) ||
            MIN_FONT_SIZE;
        document.documentElement.style.fontSize = `${fontSize}px`;

        const updateButtonsState = () => {
            btns.resizePlus.classList.toggle(
                'accessibility__btn--disabled',
                fontSize >= MAX_FONT_SIZE
            );
            btns.resizePlus.disabled = fontSize >= MAX_FONT_SIZE;

            btns.resizeMinus.classList.toggle(
                'accessibility__btn--disabled',
                fontSize <= MIN_FONT_SIZE
            );
            btns.resizeMinus.disabled = fontSize <= MIN_FONT_SIZE;
        };

        const updateSliders = () => sliders.forEach((slide) => slide.update());

        const show = accessibility.querySelector('.accessibility__show');
        const list = accessibility.querySelector('.accessibility__list');
        show.addEventListener('click', () => {
            list.classList.toggle('accessibility__list--opened');
            navigation
                .querySelectorAll('.submenu--active')
                .forEach((submenu) =>
                    submenu.classList.remove('submenu--active')
                );
        });

        const btns = {
            resizePlus: accessibility.querySelector(
                '.accessibility__btn--resize-plus'
            ),
            resizeMinus: accessibility.querySelector(
                '.accessibility__btn--resize-minus'
            ),
        };

        const handleFontSizeChange = (action) => {
            switch (action) {
                case 'resize-plus':
                    fontSize = Math.min(fontSize + 1, MAX_FONT_SIZE);
                    break;
                case 'resize-minus':
                    fontSize = Math.max(fontSize - 1, MIN_FONT_SIZE);
                    break;
                case 'grayscale':
                    document.body.classList.toggle('body--grayscale');
                    localStorage.setItem(
                        'grayscale',
                        document.body.classList.contains('body--grayscale')
                    );
                    break;
                case 'reset':
                    fontSize = MIN_FONT_SIZE;

                    document.body.classList.remove('body--grayscale');
                    localStorage.setItem('grayscale', false);
                    break;
            }

            document.documentElement.style.fontSize = `${fontSize}px`;
            localStorage.setItem('documentFontSize', fontSize);
            updateButtonsState();
            updateSliders();
        };

        list.querySelectorAll(
            '.accessibility__item .accessibility__btn'
        ).forEach((btn) => {
            btn.addEventListener('click', () =>
                handleFontSizeChange(btn.dataset.action)
            );
        });

        updateButtonsState();
    }

    // Handle Q&A section
    const qnaItems = document.querySelectorAll('.qna__item');
    qnaItems.forEach((qnaItem) => {
        qnaItem.querySelector('.qna__btn')?.addEventListener('click', () => {
            const isOpen = !qnaItem.classList.contains('qna__item--hidden');
            const isHasListOpenedItems = Boolean(
                [...qnaItems].filter(
                    (qnaItem) =>
                        !qnaItem.classList.contains('qna__item--hidden')
                ).length
            );
            qnaItems.forEach((item) => item.classList.add('qna__item--hidden'));
            if (!isOpen)
                setTimeout(
                    () => qnaItem.classList.remove('qna__item--hidden'),
                    isHasListOpenedItems * 300
                );
        });
    });

    const initSliders = () => {
        const bannerSlider = document.querySelector('.banner__slider');
        if (bannerSlider) {
            const bannerCarousel = new Glide(bannerSlider, {
                type: 'carousel',
                autoplay: 3500,
                hoverpause: true,
            }).mount();

            sliders.find(
                (slider) => slider.selector == bannerCarousel.selector
            ) || sliders.push(bannerCarousel);
        }

        const galleries = document.querySelectorAll(
            '.content .wp-block-gallery:not(.academy-carousel)'
        );

        galleries.forEach((gallery, galIndex) => {
            const galleryPictures = gallery.querySelectorAll('.wp-block-image');

            const gallerySection = document.createElement('section');
            gallerySection.className = `academy-gallery academy-gallery--${galIndex}`;

            const galleryTrack = document.createElement('div');
            galleryTrack.setAttribute('data-glide-el', 'track');
            galleryTrack.classList.add('academy-gallery__track');

            const galleryList = document.createElement('ul');
            galleryList.classList.add('academy-gallery__list');

            const createNavigationButton = (classNames, text, action) => {
                const button = document.createElement('button');
                button.classList.add(...classNames);
                button.innerHTML = text;
                button.onclick = action;
                return button;
            };

            const prevButton = createNavigationButton(
                ['academy-gallery__button', 'academy-gallery__button--prev'],
                '&lt;',
                () => galleryCarousel.go('<')
            );
            const nextButton = createNavigationButton(
                ['academy-gallery__button', 'academy-gallery__button--next'],
                '&gt;',
                () => galleryCarousel.go('>')
            );
            const closeArea = createNavigationButton(
                ['academy-gallery__close-area'],
                '×',
                () => closeGallery()
            );
            const closeButton = createNavigationButton(
                ['academy-gallery__button', 'academy-gallery__button--close'],
                '×',
                () => closeGallery()
            );
            closeArea.onfocus = () => closeButton.focus();

            let openTrigger;
            const closeGallery = () => {
                gallerySection.classList.remove('academy-gallery--active');
                closeArea.classList.remove(
                    'academy-gallery__close-area--active'
                );
                document.removeEventListener('keydown', trapFocusHandler);
                openTrigger.focus();
            };

            const trapFocusHandler = (event) => {
                const focusableElements = gallerySection.querySelectorAll(
                    'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
                );
                const firstElement = focusableElements[0];
                const lastElement =
                    focusableElements[focusableElements.length - 1];

                if (event.key === 'Tab') {
                    if (
                        event.shiftKey &&
                        document.activeElement === firstElement
                    ) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            galleryPictures.forEach((pic, picIndex) => {
                const galleryItem = document.createElement('li');
                galleryItem.classList.add('academy-gallery__item');

                const canvas = pic.querySelector('canvas');
                if (canvas) {
                    const newCanvas = document.createElement('canvas');
                    newCanvas.width = canvas.width;
                    newCanvas.height = canvas.height;

                    const context = newCanvas.getContext('2d');
                    context.drawImage(canvas, 0, 0);

                    galleryItem.append(newCanvas);
                } else galleryItem.append(pic.cloneNode(true));

                galleryList.append(galleryItem);

                const btn = document.createElement('button');
                btn.className = 'academy-gallery__picture-button';
                btn.setAttribute('gallery-index', galIndex);
                btn.setAttribute('picture-index', picIndex);
                btn.append(...pic.children);
                pic.append(btn);

                btn.onclick = () => {
                    gallerySection.classList.add('academy-gallery--active');
                    closeArea.classList.add(
                        'academy-gallery__close-area--active'
                    );
                    openTrigger = btn;

                    document.addEventListener('keydown', trapFocusHandler);

                    galleryCarousel.settings.animationDuration = 0;
                    galleryCarousel.update();
                    galleryCarousel.go(`=${picIndex}`);

                    setTimeout(() => {
                        galleryCarousel.settings.animationDuration = 400;
                        galleryCarousel.update();

                        nextButton.focus();
                    }, 100);
                };
            });

            gallerySection.append(
                closeButton,
                prevButton,
                nextButton,
                closeArea
            );
            galleryTrack.append(galleryList);
            gallerySection.append(galleryTrack);
            gallery.closest('.content').append(gallerySection);

            const galleryCarousel = new Glide(`.academy-gallery--${galIndex}`, {
                type: 'carousel',
            }).mount();
            sliders.push(galleryCarousel);
        });

        const carousels = document.querySelectorAll('.academy-carousel');
        if (carousels.length) {
            carousels.forEach((carousel) => {
                const carouselBefore = carousel.previousSibling;

                const carouselBlock = document.createElement('div');
                carouselBlock.classList = 'academy-carousel';

                const carouselTrack = document.createElement('div');
                carouselTrack.classList = 'academy-carousel__track';
                carouselTrack.setAttribute('data-glide-el', 'track');

                carousel.classList.remove('academy-carousel');
                carousel.classList.add('academy-carousel__list');

                carouselTrack.append(carousel);
                carouselBlock.append(carouselTrack);

                carouselBefore.after(carouselBlock);

                const carouselItems =
                    carousel.querySelectorAll('.wp-block-image');

                if (carouselItems.length) {
                    carouselItems.forEach((carouselItem) => {
                        const carouselItemPic =
                            carouselItem.querySelector('img');
                        carouselItemPic.classList.add(
                            'academy-carousel__picture'
                        );

                        const carouselItemPicPlaceholder =
                            carouselItemPic.cloneNode(true);
                        carouselItemPicPlaceholder.classList.add(
                            'academy-carousel__picture--placeholder'
                        );
                        carouselItemPic.after(carouselItemPicPlaceholder);

                        carouselItem.classList.add('academy-carousel__item');
                    });

                    const carouselSlider = new Glide(carouselBlock, {
                        type: 'carousel',
                        autoplay: 3500,
                        hoverpause: true,
                    }).mount();

                    galleryCarousel.push(carouselSlider);
                }
            });
        }
    };

    const concertProgram = document.querySelector('.concert-program');

    if (concertProgram) {
        const workerPath =
            '/wp-content/themes/dance-academy/assets/js/lib/pdf.worker.min.js';

        GlobalWorkerOptions.workerSrc = `${window.location.origin}${workerPath}`;

        const pdfUrl = concertProgram.getAttribute('data-src');
        const galleryContainer = concertProgram.querySelector(
            '.concert-program__gallery'
        );

        async function renderPdf(pdfUrl) {
            try {
                const pdf = await getDocument(pdfUrl).promise;

                const pages = [];

                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);

                    const scale = 1;
                    const viewport = page.getViewport({ scale: scale });

                    const figure = document.createElement('figure');
                    figure.classList.add('wp-block-image', 'size-large');

                    const newCanvas = document.createElement('canvas');
                    figure.appendChild(newCanvas);

                    newCanvas.width = viewport.width;
                    newCanvas.height = viewport.height;

                    pages.push({
                        pageNum: pageNum,
                        renderContext: {
                            canvasContext: newCanvas.getContext('2d'),
                            viewport: viewport,
                        },
                        page: page,
                        figure: figure,
                        canvas: newCanvas,
                    });
                }

                const galleryWrapper = document.createElement('figure');
                galleryWrapper.classList.add(
                    'wp-block-gallery',
                    'has-nested-images',
                    'columns-4',
                    'is-cropped',
                    'is-layout-flex',
                    'wp-block-gallery-is-layout-flex'
                );

                for (const pageData of pages) {
                    const { renderContext, page, figure } = pageData;

                    await page.render(renderContext).promise;
                    galleryWrapper.appendChild(figure);
                }

                galleryContainer.appendChild(galleryWrapper);

                initSliders();
            } catch (error) {
                console.log(
                    'Помилка при завантаженні або рендерингу PDF: ' + error
                );
            }
        }

        renderPdf(pdfUrl);
    } else {
        initSliders();
    }

    // Initialize phone input masks
    document.querySelectorAll("input[type='tel']").forEach((input) => {
        Inputmask('+38 (099) 999-99-99').mask(input);
    });

    // Handle YouTube presentations
    const presentations = document.querySelectorAll('.presentation');
    presentations.forEach((presentation) => {
        const video = presentation.querySelector('.presentation__video');
        video.style.visibility = 'hidden';
        const videoURL = video?.dataset.src;
        const button = presentation.querySelector('.presentation__watch');

        const youtubeIcon = button.querySelector(
            '.presentation__icon--youtube'
        );
        const spinnerIcon = button.querySelector(
            '.presentation__icon--spinner'
        );

        button?.addEventListener('click', () => {
            youtubeIcon.remove();
            spinnerIcon.style.opacity = '1';

            video.src = `${videoURL}${
                videoURL.includes('?') ? '&' : '?'
            }autoplay=1`;

            video.addEventListener('load', () => {
                button.remove();
                video.style.visibility = 'visible';
                video.focus();
            });
            button.onclick = null;
        });
    });

    window.onresize = () => {
        setMainHeight();

        sliders.forEach((slide) => {
            slide.update();
        });
    };
});
